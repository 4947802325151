var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"my-3",attrs:{"cols":"12","sm":"11"}},[_c('h3',{staticClass:"poppins secondary-1--text fade"},[_vm._v(" Total Enrollees ")]),(_vm.filter.enrolled_from || _vm.filter.enrolled_to)?_c('FormLabel',{staticClass:"mb-3",attrs:{"label":`Enrollees ${_vm.filter.enrolled_from ? `from ${_vm.$dateFormat.mmDDyy(_vm.filter.enrolled_from)}` : ''} ${_vm.filter.enrolled_to ? `to ${_vm.$dateFormat.mmDDyy(_vm.filter.enrolled_to)}` : ''}`}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-row justify-end",attrs:{"cols":"12","sm":"1"}},[_c('FilterMenu',{attrs:{"initialFilter":_vm.filter},on:{"resetFilters":_vm.clearFilters,"applyFilters":_vm.getBarGraphData}},[_c('v-sheet',{staticClass:"overflow-y-auto scroller",attrs:{"max-height":"350"}},[_c('FormSelectObject',{attrs:{"label":'STATUS',"items":_vm.status,"value":_vm.filter.status},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status", $event)}}}),_c('FormSelectObject',{staticClass:"mt-3",attrs:{"label":'TYPE OF TRAINING',"items":_vm.learning_modes,"value":_vm.filter.learning_mode},on:{"update:value":function($event){return _vm.$set(_vm.filter, "learning_mode", $event)}}}),_c('div',{staticClass:"mt-3"},[_c('FormLabel',{staticClass:"mb-1",attrs:{"label":'COURSE CONDUCTED DATE (YYYY-MM-DD)'}})],1),_c('label',{staticClass:"caption"},[_vm._v("FROM:")]),_c('FormDatePicker',{attrs:{"val":_vm.filter.date_from,"max":_vm.maxDate,"class_":"col-11"},on:{"apply":(e) => {
                            _vm.filter.date_from = e
                            _vm.minDate = e
                        }}}),_c('label',{staticClass:"caption"},[_vm._v("TO:")]),_c('FormDatePicker',{attrs:{"val":_vm.filter.date_to,"min":_vm.minDate,"class_":"col-11"},on:{"apply":(e) => {
                            _vm.filter.date_to = e
                            _vm.maxDate = e
                        }}}),_vm._v("` "),_c('div',[_c('FormLabel',{staticClass:"mb-1",attrs:{"label":'ENROLLMENT DATE (YYYY-MM-DD)'}})],1),_c('label',{staticClass:"caption"},[_vm._v("FROM:")]),_c('FormDatePicker',{attrs:{"val":_vm.filter.enrolled_from,"max":_vm.maxDate2,"class_":"col-11"},on:{"apply":(e) => {
                            _vm.filter.enrolled_from = e
                            _vm.minDate2 = e
                        }}}),_c('label',{staticClass:"caption"},[_vm._v("TO:")]),_c('FormDatePicker',{attrs:{"val":_vm.filter.enrolled_to,"min":_vm.minDate2,"class_":"col-11"},on:{"apply":(e) => {
                            _vm.filter.enrolled_to = e
                            _vm.maxDate2 = e
                        }}}),_vm._v("` ")],1)],1)],1)],1),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mb-1",attrs:{"height":500,"type":"image"}}):_c('v-card',{staticClass:"custom-border border pa-5 mb-5"},[_c('ApexHorizontalBarGraph',{attrs:{"series":_vm.chartData,"filename":'Enrollments',"loading":_vm.loading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }